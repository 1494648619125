const encrypt = (text) => {
  try {
    let crypto = require('crypto')

    let mykey = crypto.createCipher('aes-128-cbc', 'mypassword')
    let mystr = mykey.update(text, 'utf8', 'hex')
    mystr += mykey.final('hex')

    return mystr
  } catch (error) {
    return null
  }
}

const decrypt = (text) => {
  try {
    let crypto = require('crypto')

    let mykey = crypto.createDecipher('aes-128-cbc', 'mypassword')
    let mystr = mykey.update(text, 'hex', 'utf8')
    mystr += mykey.final('utf8')

    return mystr
  } catch (error) {
    return null
  }
}

export {
  encrypt,
  decrypt
}
