import ModalConfirm from './ModalConfirm'

const Modal = {
  Confirm: (props) => {
    const config = {
      type: 'confirm',
      okCancel: true,
      ...props
    }
    return (<ModalConfirm {...config} />)
  },
  Success: (props) => {
    const config = {
      type: 'success',
      okCancel: false,
      ...props
    }
    return (<ModalConfirm {...config} />)
  },
  Info: (props) => {
    const config = {
      type: 'info',
      okCancel: false,
      ...props
    }
    return (<ModalConfirm {...config} />)
  },
  Warning: (props) => {
    const config = {
      type: 'warning',
      okCancel: false,
      ...props
    }
    return (<ModalConfirm {...config} />)
  },
  Error: (props) => {
    const config = {
      type: 'error',
      okCancel: false,
      ...props
    }
    return (<ModalConfirm {...config} />)
  }
}

export default Modal
