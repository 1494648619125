import React from 'react'

export default function DineInIcon () {
  return (
    <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_348_30)">
        <path d="M19.5 40C30.2696 40 39 31.0457 39 20C39 8.9543 30.2696 0 19.5 0C8.73045 0 0 8.9543 0 20C0 31.0457 8.73045 40 19.5 40Z" fill="#F15249" />
        <path d="M11.3188 29.022L21.8378 39.8107C30.6958 38.7381 37.7084 31.6088 38.8157 22.5236L28.2353 11.6719L11.3188 29.022Z" fill="#AD0E0E" />
        <path d="M28.7271 24.4163C29.5883 25.2996 29.5883 26.8138 28.7271 27.6971L27.0047 29.4637C26.1435 30.3469 24.6672 30.3469 23.806 29.4637L10.2729 15.5835C9.41165 14.7003 9.41165 13.1861 10.2729 12.3028L12.0568 10.4731C12.918 9.58985 14.3943 9.58985 15.2555 10.4731L28.7271 24.4163Z" fill="white" />
        <path d="M21.5915 17.0347L16.6089 22.1451L23.806 29.4637C24.6672 30.347 26.1436 30.347 27.0048 29.4637L28.7887 27.634C29.6499 26.7508 29.6499 25.2366 28.7887 24.3533L21.5915 17.0347Z" fill="#D6D6D6" />
        <path d="M23.806 10.5362C24.6672 9.65296 26.1435 9.65296 27.0047 10.5362L28.7886 12.3659C29.6498 13.2492 29.6498 14.7634 28.7886 15.6466L15.194 29.4637C14.3328 30.347 12.8564 30.347 11.9952 29.4637L10.2729 27.6971C9.41165 26.8138 9.41165 25.2996 10.2729 24.4164L23.806 10.5362Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_348_30">
          <rect width="39" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>

  )
}
