import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import color from 'theme/color'
import CancelIcon from '../../../static/Icon/svg/CancelIcon'

const styles = () => ({
  buttonDelivery: {
    backgroundColor: 'white',
    display: 'block',
    width: '100%',
    borderRadius: '6px',
    padding: '10px',
    boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 15px'
  }
})

class CancelButton extends Component {
  render () {
    const {
      classes,
      setDineIn
    } = this.props
    return (
      <div className={classes.buttonDelivery} onClick={() => { setDineIn(false) }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm container>
            <Grid item
              xs
              container
              direction="column"
            >
              <Grid item style={{ display: 'flex' }}>
                <Grid item xs={2} style={{ alignSelf: 'center', display: 'flex' }}>
                  <CancelIcon />
                </Grid>
                <Grid style={{ marginLeft: 2, alignSelf: 'center' }} item>
                  <div style={{ color: color.colorRed, fontWeight: 'bolder', fontSize: '16px' }}>
                    Batal
                  </div>
                  <div style={{ fontSize: '12px', fontWeight: 500, color: '#757575' }}>
                    Batalkan dine in
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  shoppingCartId: state.shoppingCartStore.id
})

export default connect(mapStateToProps)(withStyles(styles)(CancelButton))
