import React from 'react'
import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode'

const qrcodeRegionId = 'html5qr-code-full-region'

class Html5QrcodePlugin extends React.Component {
  componentDidMount () {
    const { qrCodeSuccessCallback } = this.props
    // Creates the configuration object for Html5QrcodeScanner.
    function createConfig (props) {
      let config = {}
      if (props.fps) {
        config.fps = props.fps
      }
      if (props.qrbox) {
        config.qrbox = props.qrbox
      }
      if (props.aspectRatio) {
        config.aspectRatio = props.aspectRatio
      }
      if (props.disableFlip !== undefined) {
        config.disableFlip = props.disableFlip
      }
      config.formatsToSupport = [
        Html5QrcodeSupportedFormats.QR_CODE
      ]
      return config
    }

    const config = createConfig(this.props)

    this.html5Qrcode = new Html5Qrcode(
      qrcodeRegionId
    )

    if (this.html5Qrcode) {
      Html5Qrcode.getCameras().then((camera) => {
        if (camera && camera[0]) {
          this.html5Qrcode.start(
            {
              facingMode: 'environment'
            },
            config,
            (decodedText) => {
              qrCodeSuccessCallback(decodedText)
            },
            (errorMessage) => {
              throw errorMessage
            }
          ).catch((err) => {
            throw err
          })
        }
      })
    }
  }

  componentWillUnmount () {
    // TODO(mebjas): See if there is a better way to handle
    //  promise in `componentWillUnmount`.
    if (this.html5Qrcode) {
      this.html5Qrcode.stop().catch((error) => {
        console.error('Failed to clear html5QrcodeScanner.', error)
      })
    }
  }

  render () {
    return <div id={qrcodeRegionId} />
  }
}

export default Html5QrcodePlugin
