import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const DialogConfirm = ({
  title,
  content,
  visible,
  onCancel,
  cancelText,
  okCancel,
  okText,
  onOk
}) => {
  return (
    <div>
      <Dialog
        open={visible}
        onClose={() => {
          if (onCancel) { onCancel() }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title || 'Confirmation'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content || ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {okCancel && (
            <Button onClick={onCancel} color="default">
              {cancelText || 'Cancel'}
            </Button>
          )}
          <Button onClick={onOk} color="primary">
            {okText || 'Ok'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DialogConfirm
