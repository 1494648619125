import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import { Badge } from '@material-ui/core'
import { connect } from 'react-redux'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import color from 'theme/color'
import Router from 'next/router'
import HomeIcon from '../../../static/Icon/svg/HomeIcon'
import CartIcon from '../../../static/Icon/svg/CartIcon'
import TransactionIcon from '../../../static/Icon/svg/TransactionIcon'
import ProfileIcon from '../../../static/Icon/svg/ProfileIcon'

const style = {
  root: {
    width: '100%',
    height: 'auto'
  },
  primary: {
    fontSize: '24px'
  },
  selected: {
    '&$selected': {
      color: color.primaryColor
    }
  }
}

class PrimaryBottomNavigation extends React.Component {
  handleChange = (link, linkTab) => {
    if (typeof linkTab === 'string' || typeof linkTab === 'number') {
      Router.push({
        pathname: link,
        query: { tab: linkTab }
      })
    } else {
      Router.push(`${link}`)
    }
  }

  render () {
    const { classes, value, cartCount } = this.props

    const listNavigation = [
      {
        name: 'Beranda',
        link: '/home',
        icon: <HomeIcon />
      },
      {
        name: 'Keranjang',
        link: '/cart',
        icon: (
          <Badge badgeContent={cartCount || 0} color="error">
            <CartIcon />
          </Badge>
        )
      },
      {
        name: 'Riwayat',
        link: '/history-order',
        icon: <TransactionIcon />
      },
      {
        name: 'Profil',
        link: '/user-profile',
        icon: <ProfileIcon />
      }
    ]

    return (
      <Container maxWidth="xs"
        style={{
          position: 'fixed', bottom: 0, padding: '0px', zIndex: 999
        }}
      >
        <div className={classes.root}>
          <BottomNavigation
            value={value}
            showLabels
            className={classes.root}
          >
            {listNavigation.map((item, index) => (
              <BottomNavigationAction key={index}
                label={item.name}
                value={item.link}
                icon={item.icon}
                classes={{ selected: classes.selected }}
                onClick={() => this.handleChange(item.link, item.tab)}
              />
            ))}
          </BottomNavigation>
        </div>
      </Container>
    )
  }
}

PrimaryBottomNavigation.propTypes = {
  classes: PropTypes.object.isRequired
}

const PrimaryBottomNavigationWithStyles = withStyles(style)(PrimaryBottomNavigation)

const mapStateToProps = (state) => ({
  cartCount: state.shoppingCartStore.cartCount
})

const PrimaryBottomNavigationWithRedux = connect(mapStateToProps)(PrimaryBottomNavigationWithStyles)

export default PrimaryBottomNavigationWithRedux
