import React from 'react'

export default function CartIcon () {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.7057 8.39842V12.3047C24.7057 12.8441 24.2737 13.2812 23.7406 13.2812H23.106L22.6994 16.9428C22.644 17.4417 22.2263 17.8102 21.7415 17.8104C21.7055 17.8104 21.6693 17.8083 21.6326 17.8041C21.1029 17.7441 20.7218 17.2605 20.7813 16.7248L21.2842 12.1958C21.3391 11.7018 21.7519 11.3281 22.2433 11.3281H22.7758V9.37498H1.93015V11.3281H18.3847C18.9177 11.3281 19.3497 11.7655 19.3497 12.3047C19.3497 12.8441 18.9177 13.2812 18.3847 13.2812H3.55984L4.51285 21.3263C4.62897 22.3072 5.45286 23.0469 6.42924 23.0469H18.3539C19.3375 23.0469 20.1621 22.3009 20.272 21.3118C20.3314 20.7758 20.809 20.3903 21.3387 20.4504C21.8684 20.5107 22.2495 20.994 22.1901 21.53C21.9703 23.5081 20.3212 25 18.3539 25H6.42924C4.47648 25 2.82869 23.5205 2.59628 21.5586L1.61593 13.2812H0.965075C0.432022 13.2812 0 12.8441 0 12.3047V8.39842C0 7.85902 0.432022 7.42186 0.965075 7.42186H4.38807L9.4877 0.39842C9.80323 -0.0360741 10.4072 -0.129725 10.8367 0.189756C11.2663 0.509047 11.3587 1.12016 11.0431 1.55485L6.78323 7.42186H17.9583L13.6982 1.55485C13.3827 1.12016 13.475 0.509047 13.9046 0.189756C14.3342 -0.129725 14.9381 -0.0362649 15.2538 0.39842L20.3533 7.42186H23.7406C24.2737 7.42186 24.7057 7.85921 24.7057 8.39842ZM11.3879 16.2109V20.1172C11.3879 20.6566 11.8199 21.0937 12.353 21.0937C12.8858 21.0937 13.318 20.6566 13.318 20.1172V16.2109C13.318 15.6715 12.8858 15.2344 12.353 15.2344C11.8199 15.2344 11.3879 15.6715 11.3879 16.2109ZM15.2482 16.2109V20.1172C15.2482 20.6566 15.6802 21.0937 16.2133 21.0937C16.7461 21.0937 17.1783 20.6566 17.1783 20.1172V16.2109C17.1783 15.6715 16.7461 15.2344 16.2133 15.2344C15.6802 15.2344 15.2482 15.6715 15.2482 16.2109ZM7.52758 16.2109V20.1172C7.52758 20.6566 7.9596 21.0937 8.49266 21.0937C9.02552 21.0937 9.45773 20.6566 9.45773 20.1172V16.2109C9.45773 15.6715 9.02552 15.2344 8.49266 15.2344C7.9596 15.2344 7.52758 15.6715 7.52758 16.2109Z" fill="currentColor" />
    </svg>

  )
}
