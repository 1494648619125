import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import color from 'theme/color'
import Image from 'next/image'

const styles = () => ({
  buttonDelivery: {
    backgroundColor: 'white',
    display: 'block',
    width: '100%',
    borderRadius: '6px',
    padding: '10px',
    boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 15px'
  }
})

class DineInButton extends Component {
  render () {
    const {
      classes,
      setDineIn,
      val
    } = this.props
    return (
      <div className={classes.buttonDelivery} onClick={() => { if (setDineIn) { setDineIn(val) } }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm container>
            <Grid item
              xs
              container
              direction="column"
            >
              <Grid item style={{ display: 'flex' }}>
                <Grid item xs={2} style={{ alignSelf: 'center', display: 'flex' }}>
                  <Image
                    loader={({ src }) => {
                      return src
                    }}
                    unoptimized
                    src="/static/Icon/DineInButton.png"
                    alt="delivery"
                    width={40}
                    height={40}
                  />
                </Grid>
                <Grid style={{ marginLeft: 12, alignSelf: 'center' }} item>
                  <div style={{ color: color.secondaryColor, fontWeight: 'bolder', fontSize: '16px' }}>
                    Dine In
                  </div>
                  <div style={{ fontSize: '12px', fontWeight: 500, color: '#757575' }}>
                    Makan ditempat tanpa perlu antri
                  </div>
                  <div style={{ fontSize: '12px', fontWeight: 500, color: '#757575' }}>
                    Tekan untuk scan QR
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  shoppingCartId: state.shoppingCartStore.id
})

export default connect(mapStateToProps)(withStyles(styles)(DineInButton))
